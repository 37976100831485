import axios from "axios";
export var IP = process.env.REACT_APP_SERVER_IP || "http://localhost:3000";
export var api = axios.create({
    baseURL: IP,
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: true,
    timeoutErrorMessage: "Request timed out"
});
