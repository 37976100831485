var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { api } from "../../api/API";
import { useUser } from "../../utils/UserProvider";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
var cookies = new Cookies();
var verificationInterval = 30 * 60 * 1000;
export var useVerifyToken = function () {
    var _a = useUser(), setUserData = _a.setUserData, logout = _a.logout;
    var navigate = useNavigate();
    var queryClient = useQueryClient();
    var queryResult = useQuery({
        queryKey: ["verifyToken"],
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var accessToken, refreshToken, userVerify, userData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        accessToken = cookies.get("accessToken");
                        refreshToken = cookies.get("refreshToken");
                        if (!(accessToken || refreshToken)) return [3 /*break*/, 5];
                        api.defaults.headers.common["Authorization"] = "Bearer ".concat(accessToken);
                        return [4 /*yield*/, api.post("/verify-token")];
                    case 1:
                        userVerify = _a.sent();
                        if (!userVerify.data.success) return [3 /*break*/, 3];
                        return [4 /*yield*/, api.get("/user")];
                    case 2:
                        userData = _a.sent();
                        if (userData.data.success) {
                            setUserData(userData.data.data);
                        }
                        else {
                            throw new Error("User data fetch failed");
                        }
                        return [2 /*return*/, true];
                    case 3: throw new Error("Token verification failed");
                    case 4: return [3 /*break*/, 6];
                    case 5: throw new Error("No tokens present");
                    case 6: return [2 /*return*/];
                }
            });
        }); },
        retry: false,
        staleTime: verificationInterval
    });
    useEffect(function () {
        var handleError = function () { return __awaiter(void 0, void 0, void 0, function () {
            var refreshed;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(queryResult.isError && queryResult.error.message === "Token verification failed")) return [3 /*break*/, 5];
                        return [4 /*yield*/, refreshToken()];
                    case 1:
                        refreshed = _a.sent();
                        if (!refreshed) return [3 /*break*/, 3];
                        return [4 /*yield*/, queryClient.invalidateQueries({
                                queryKey: ["verifyToken"]
                            })];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        handleTokenFailure();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        if (queryResult.isError) {
                            handleTokenFailure();
                        }
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        handleError();
    }, [queryResult.isError, queryResult.error]);
    var handleTokenFailure = function () {
        cookies.remove("accessToken");
        cookies.remove("refreshToken");
        navigate("/", {
            replace: true
        });
    };
    return queryResult;
};
var refreshToken = function () { return __awaiter(void 0, void 0, void 0, function () {
    var refreshToken, response, newAccessToken;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                refreshToken = cookies.get("refreshToken");
                if (!refreshToken) return [3 /*break*/, 2];
                return [4 /*yield*/, api.post("/refresh-token", {
                        refresh_token: refreshToken
                    })];
            case 1:
                response = _a.sent();
                if (response.data.success) {
                    newAccessToken = response.data.access_token;
                    cookies.set("accessToken", newAccessToken, {
                        path: "/",
                        secure: true,
                        sameSite: "strict",
                        expires: new Date(Date.now() + 60 * 60 * 1000)
                    });
                    api.defaults.headers.common["Authorization"] = "Bearer ".concat(newAccessToken);
                    return [2 /*return*/, true];
                }
                _a.label = 2;
            case 2: return [2 /*return*/, false];
        }
    });
}); };
